import Vue from "vue";
import App from "./App.vue";
import VueWaypoint from "vue-waypoint";
Vue.config.productionTip = false;

// Waypoint plugin
Vue.use(VueWaypoint);

new Vue({
  render: (h) => h(App),
}).$mount("#app");
