<template lang="pug">
.app(:data-step="step")
  .app-header.bg-light.d-flex.align-items-center.ps-3
    img.app-logo.w-auto(src="@/assets/images/logo.png")

  .container.pb-3
    .step.step-terms(v-if="step === 1")
      terms
      div(v-waypoint='{ active: true, callback: onWaypoint, options: intersectionOptions }')
    .step.step-register(v-if="step === 2")
      .mx-n3.mb-3
        img.w-100(src="@/assets/images/banner.jpg")

      h2.title.text-center.text-primary.border-bottom.pb-3.mb-4 ลงทะเบียน

      form.d-grid.gap-4.mb-4(@submit.prevent="onSubmit", novalidate)
        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="name") ชื่อ - นามสกุล
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#name.form-control.form-control-lg(
            type="text",
            v-model="form.name",
            required
          )
          .invalid-feedback กรุณากรอก ชื่อ - นามสกุล

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="phone") เบอร์ติดต่อ
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#phone.form-control.form-control-lg(
            type="tel",
            v-model="form.phone",
            minlength="9",
            maxlength="10",
            required
          )
          .invalid-feedback กรุณากรอก เบอร์ติดต่อ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="address") ที่อยู่
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)

          textarea#address.form-control.form-control-lg(
            v-model="form.address",
            rows="2",
            required
          )
          .invalid-feedback กรุณากรอก ที่อยู่ในการจัดส่ง


        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="phone") จังหวัด
          select.form-select.form-select-lg(v-model="form.province" required)
            option(value="" disabled) เลือกจังหวัด
            option(v-for="{ province } in provinces" :value="province") {{ province }}
          .invalid-feedback กรุณาเลือกจังหวัด


        .form-group
          label.form-label.fs-14.mb-3.opacity-50(for="zipcode") รหัสไปรษณีย์
          input#zipcode.form-control.form-control-lg(
            type="tel",
            v-model="form.zipcode",
            maxlength="10",
            required
          )
          .invalid-feedback กรุณากรอก รหัสไปรษณีย์
          
        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block โปรสินเชื่อเพื่อที่อยู่อาศัยที่ตรงใจคุณมากที่สุด
          .d-grid.gap-3
            label(
              v-for="({ value, label }, index) in choices",
              :key="value",
              :for="`choice-${value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-3.px-3
                input.d-none(
                  v-model="form.answer",
                  type="radio",
                  :value="value",
                  :id="`choice-${value}`"
                  required
                ) 
                svg(
                  width="16",
                  height="16",
                  viewbox="0 0 16 16",
                  fill="#d8dbdf",
                  xmlns="http://www.w3.org/2000/svg"
                ).me-3.flex-shrink-0
                  path(
                    d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71978 15.75 0.25 12.2802 0.25 8C0.25 3.71978 3.71978 0.25 8 0.25C12.2802 0.25 15.75 3.71978 15.75 8ZM7.10356 12.1036L12.8536 6.35356C13.0488 6.15831 13.0488 5.84172 12.8536 5.64647L12.1465 4.93937C11.9512 4.74409 11.6346 4.74409 11.4393 4.93937L6.75 9.62869L4.56066 7.43934C4.36541 7.24409 4.04881 7.24409 3.85353 7.43934L3.14644 8.14644C2.95119 8.34169 2.95119 8.65828 3.14644 8.85353L6.39644 12.1035C6.59172 12.2988 6.90828 12.2988 7.10356 12.1036Z"
                  )
                span.d-block.lh-sm.py-1(v-html="label")

          .invalid-feedback(
            :class="{ 'd-block': !form.answer && submited }"
          ) กรุณาเลือก โปรสินเชื่อเพื่อที่อยู่อาศัยที่ตรงใจคุณมากที่


        p.text-center.opacity-50
          strong หมายเหตุ
          |
          | : กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน <br> เพื่อประโยชน์ในการร่วมกิจกรรมของท่าน


        .alert.alert-danger(ref="alert" v-if="errorMessages" v-html="errorMessages")

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 3")
      .w-100
        happy-student.mb-5
        h2.h1.text-primary.mb-4 ขอบคุณที่เข้าร่วมกิจกรรม
        p.text-center.mb-3 เจ้าหน้าที่จะทำการติดต่อท่านไปตามข้อมูลที่ท่าน <br> ได้ทำการลงทะเบียนหากท่านได้รับรางวัล
        h4 ประกาศผลกิจกรรม
        p วันที่ 13 สิงหาคม 2565 <br> เวลา 18.00 น
        
    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 4")
      .w-100
        finish.mb-5
        h2.h1.text-primary.mb-4 สิ้นสุดการเข้าร่วมกิจกรรม
        p.text-center.mb-3 โปรดติดตามกิจกรรมจากทางธนาคาร <br> ในครั้งถัดไปเร็ว ๆ นี้

    .app-action.position-fixed.start-0.end-0.bottom-0.p-3
      template(v-if="step === 1")
        .pb-2.text-center.fs-14(for="acceptTerm") ข้าพเจ้ายอมรับในข้อตกลงเเละเงื่อนไข
        .d-flex.gap-2

          button.btn.btn-lg.btn-secondary.rounded-pill.w-50(
            :disabled="!hasScrolledToBottom || isLoading"
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
            @click="success"
          ) ไม่ยอมรับ

          button.btn.btn-lg.btn-block.rounded-pill.text-white.w-50.btn-primary(
            type="button",
            :disabled="!hasScrolledToBottom || isLoading",
            @click="acceptTerm",
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
          ) 
            span.spinner-border.spinner-border-sm(
              v-if="isLoading",
              role="status",
              aria-hidden="true"
            )
            span(v-else) ยอมรับ
        

      template(v-if="step === 2")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="submit",
          :disabled="isLoading",
          @click="onSubmit"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-else) ส่งคำตอบ

      template(v-if="step === 3 || step === 4")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          @click="success"
        )
          span(v-if="step === 4") ปิดหน้าต่าง
          span(v-else) เสร็จสิ้น
</template>

<script>
import axios from "axios";
import "./assets/bootstrap.scss";

import provinces from "@/data/provinces.json";
import liff from "@line/liff";
import Terms from "@/components/Terms.vue";
import HappyStudent from "@/components/HappyStudent.vue";
import DonePana from "@/components/DonePana.vue";

import Close from "@/components/Close.vue";
import Done from "@/components/Done.vue";
import Finish from "@/components/Finish.vue";

export default {
  components: { Terms, HappyStudent, DonePana, Done, Close, Finish },
  data() {
    return {
      provinces: provinces.data,
      form: { province: "" },
      step: 1,
      profile: {},
      isLoading: false,
      submited: false,
      errorMessages: "",
      hasScrolledToBottom: false,
      choices: [
        {
          label: "กู้ร่วมกับใครก็ได้",
          value: "1",
        },
        {
          label: `เงินงวดต่ำ`,
          value: "2",
        },
        {
          label: `ฟรี ประกันอัคคีภัย 5 ปี`,
          value: "3",
        },
        {
          label: `ดอกเบี้ยต่ำกว่าตลาด`,
          value: "4",
        },
      ],
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
    };
  },

  watch: {
    step() {
      window.scrollTo(0, 0);
    },
  },

  async mounted() {
    this.liffInit();
  },

  methods: {
    async liffInit() {
      await liff.init({ liffId: "1655692268-o1d6w9aw" });

      if (liff.isLoggedIn()) {
        const isFriend = await this.getFriend();
        if (!isFriend) {
          alert("กรุณาเพิ่มเพื่อน GHB Buddy");
          window.location = "https://line.me/R/ti/p/@ghbbuddy";
        } else {
          await this.getProfile();
        }
      } else {
        liff.login();
      }
    },

    async getFriend() {
      const friend = await liff.getFriendship();
      return friend.friendFlag;
    },

    async getProfile() {
      const profile = await liff.getProfile();
      this.profile = profile;
    },

    onSubmit() {
      this.submited = true;
      this.errorMessages = "";
      const form = document.querySelector("form");
      if (!form.checkValidity() || !this.form.answer) {
        form.classList.add("was-validated");
        const el = form.querySelector("*:invalid");
        if (el) el.scrollIntoView();
      } else {
        this.submit();
      }
    },

    async submit() {
      this.isLoading = true;
      this.form.user_id = this.profile.userId;
      this.form.accept_term = this.form.accept_term ? "ยอมรับ" : "ไม่ยอมรับ";
      console.log(this.profile);

      try {
        await axios.post(
          "https://ghbank.digitalsetup.co/api/v1/campaign/082022",
          this.form
        );
        this.step = 3;
      } catch (error) {
        this.errorMessages = error.response.data.message;

        setTimeout(() => {
          this.$refs.alert.scrollIntoView();
        }, 100);
      } finally {
        this.isLoading = false;
      }
    },

    success() {
      liff.closeWindow();
    },

    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.hasScrolledToBottom = true;
      }
    },

    acceptTerm() {
      this.step = 2;
      this.form.accept_term = 1;
    },
  },
};
</script>

<style lang="scss">
body {
  max-width: 575px;
  margin: auto;

  @media (min-width: 576px) {
    background: #f8f8f8;
  }
}

.app {
  overflow: hidden;
  padding-bottom: 80px;
  background: #fff;
  min-height: 100vh;

  &[data-step="1"] {
    padding-bottom: 115px;
  }

  &[data-step="3"] {
    background: url(assets/images/bg.jpg);
    background-size: cover;
  }

  &-header {
    height: 4.25rem;
    background-image: url(assets/images/header-bg.jpg);
    background-size: auto 4.25rem;
    background-position: top right;
    background-repeat: no-repeat;
  }

  &-logo {
    height: 2.375rem;
  }

  &-action {
    background-color: rgba(255, 255, 255, 0.6);
    backdrop-filter: saturate(200%) blur(20px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

    @media (min-width: 576px) {
      width: 575px;
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
}

.choice-item {
  &:active {
    opacity: 0.5;
  }

  input:checked + svg {
    fill: var(--bs-primary);
  }
}

.step-success {
  min-height: calc(100vh - 120px);
}
</style>
